// Generated by Framer (f318921)

import { addFonts, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, RichText, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-5xU4E"

const variantClassNames = {fasSmj2K0: "framer-v-6pdo4j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {bounce: 0.2, delay: 0, duration: 0.4, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion.create(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "fasSmj2K0", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", intrinsicHeight: 128, intrinsicWidth: 138, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 256, pixelWidth: 276, src: "https://framerusercontent.com/images/2DyZ3RTtYmeBz4wvASPtIpBtk.png"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-6pdo4j", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"fasSmj2K0"} ref={ref ?? ref1} style={{...style}}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "Q1VTVE9NO0JyYW5kb24gVGV4dCBCb2xk", "--framer-font-family": "\"Brandon Text Bold\", \"Brandon Text Bold Placeholder\", sans-serif", "--framer-font-size": "18px", "--framer-line-height": "1.56em", "--framer-text-color": "var(--extracted-r6o4lv, var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64)))"}}>Growth limiters</motion.p></React.Fragment>} className={"framer-u9o4ah"} fonts={["CUSTOM;Brandon Text Bold"]} layoutDependency={layoutDependency} layoutId={"ZvYUcPAnv"} style={{"--extracted-r6o4lv": "var(--token-bc567dab-a014-498f-95c5-57b3fd26b32c, rgb(1, 31, 64))", "--framer-link-text-color": "rgb(0, 153, 255)", "--framer-link-text-decoration": "underline"}} verticalAlignment={"top"} withExternalLayout/></Image></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-5xU4E.framer-1ib5x42, .framer-5xU4E .framer-1ib5x42 { display: block; }", ".framer-5xU4E.framer-6pdo4j { height: 128px; overflow: visible; position: relative; width: 138px; }", ".framer-5xU4E .framer-u9o4ah { flex: none; height: auto; position: absolute; right: -8px; top: 103px; white-space: pre; width: auto; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 128
 * @framerIntrinsicWidth 138
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerftiiS2oEt: React.ComponentType<Props> = withCSS(Component, css, "framer-5xU4E") as typeof Component;
export default FramerftiiS2oEt;

FramerftiiS2oEt.displayName = "big 4 gold";

FramerftiiS2oEt.defaultProps = {height: 128, width: 138};

addFonts(FramerftiiS2oEt, [{explicitInter: true, fonts: [{family: "Brandon Text Bold", source: "custom", url: "https://framerusercontent.com/assets/O5k6Mj7wtZ00a6acvallq9qvPA.otf"}]}], {supportsExplicitInterCodegen: true})